import { ErrorHandler, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ConsoleErrorsService } from './console-errors.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';


@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {


    constructor(
      protected consoleErrorsService: ConsoleErrorsService,
    ) {}

    handleError(errorToHandle: any): void {
      try {
        console.log(errorToHandle);
        this.consoleErrorsService.logError(errorToHandle);
      } catch (error) {
        console.error('Error logging to console errors to server');
      }
    }
}